<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(updateModule)"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <modules-form
          :module-data="moduleData"
        />

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'
import router from '@/router'
import ModulesForm from '../modules-form/ModulesForm.vue'
import moduleStoreModule from '../moduleStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    ModulesForm,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },

  setup() {
    const moduleData = ref({})

    const MODULE_APP_STORE_MODULE_NAME = 'app-module'

    // Register module
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.registerModule(MODULE_APP_STORE_MODULE_NAME, moduleStoreModule)

    store.dispatch('app-module/fetchModule', router.currentRoute.params.id)
      .then(response => {
        // eslint-disable-next-line prefer-destructuring
        moduleData.value = response.data.data[0]
      })
      .catch(error => {
        if (error.response.status === 404) {
          moduleData.value = undefined
        }
      })

    async function updateModule() {
      moduleData.value.operation = 'E'
      const response = await store.dispatch('app-module/procModule', moduleData.value)
      if (response?.data?.status) {
        this.$router.push({ name: 'system-settings-modules-list' })
      }
    }

    return {
      //  Data
      moduleData,

      //  Action
      updateModule,
    }
  },
}
</script>
